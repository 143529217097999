<template>
  
  <div class="col-md-7 col-sm-7 mt-10 boxmain" v-if="my_data==null">

    <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n titleh2n_news">
                  <Skeleton></Skeleton>
                </h2>

              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12col-md-12 block-1">
                <article class="art_text table-responsive">
                    <Skeleton v-for="(v,k) in 10" :key="k" height="100px"></Skeleton>
                </article>
              </div>
            </div>
          </div>
     </SkeletonTheme>
  </div>


   <div class="col-md-7 col-sm-7 mt-10 boxmain" v-else>
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n titleh2n_news">
                    {{my_data.name}}
                </h2>

              </div>
            </div>
          </div>
          <div class="container">
            <div class="row" v-if="my_bus==null">
              <div class="col-sm-12 col-xs-12 col-ms-12col-md-12 block-1">
                <article class="art_text table-responsive" v-html="my_data.content">
                  
                </article>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-sm-12 col-xs-12 col-ms-12col-md-12 block-1">
                <article class="art_text table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>
                        <p><strong>Số xe&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong></p>
                        </th>
                        <th>
                        <p><strong>Lịch trình&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong></p>
                        </th>
                        <th>
                        <p><strong>Thời gian&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong></p>
                        </th>

 <th>
                        <p><strong>Giá vé &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong></p>
                        </th>

 <th>
                        <p><strong>Chỗ ngồi  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong></p>
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(b,k) in my_bus" :key="k">
                        <td>
                        <p>Số xe: {{b.code}}</p>
                        </td>
                        <td>
                        <p>{{b.info.name}}</p>
                        </td>
                        <td>
                        <p>{{b.metadata[4].value}} </p>
                        </td>
                         <td>
                        <p v-html="b.metadata[6].value"> </p>
                        </td>
                         <td>
                        <p>{{b.metadata[5].value}} </p>
                        </td>
                      
                        
                      </tr>
                    </tbody>
                  </table>
                </article>
              </div>
            </div>

          </div>

  </div>


</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

  import axios from 'axios';
export default {
  
 
  data() {
    return {
      

      myurl:window.my_url,
      my_data:null,
      meta:{
          title:"",
          description:""
      },

      my_bus:null
      
    };
  },

  components: {
    Skeleton,
    SkeletonTheme
    
  },
  created(){
   
      

        axios.get(window.my_api+"api/page/get-pages",
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
              .then( (res)=>{
                  
                  if(res.status==200){

                       var data=res.data.data.filter( n=> n.slug==this.$route.params.slug );

                       if(data.length){
                        this.my_data=data[0];

                          if(this.my_data.slug=="so-do-tuyen-xe-bus"){
                              this.Bus();
                          }
                          

                          this.meta={
                              title:this.my_data['name'],
                              description:this.my_data['name']
                          }
                       }
                       
                    
              
                                            
                  }else {
                    console.log("ERROR",res);
                  }
                   

                });
  },
  metaInfo(){
     return {
        title:this.meta.title,
        description:this.meta.description
     }
  },
  methods:{
    Bus(){
        axios.get("http://apiservices.ebms.vn/api/routes/?apikey=P4iw3GVQ9kbYfWOR95ZcmnHT00jKKa4z")
              .then((res)=>{
                    this.my_bus=res.data;
                    
              });
    }
  }
}
</script>
